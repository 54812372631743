<template>
  <el-popover
    placement="bottom"
    trigger="click"
    @show="$emit('show')"
    @hide="$emit('hide')"
  >
    <i
      slot="reference"
      class="fas fa-ellipsis-v options-icon"
    />

    <el-button
      type="text"
      style="padding: 0"
      @click="$emit('delete-entry')"
    >
      Delete
    </el-button>
  </el-popover>
</template>

<script>
export default {
  name: 'OptionsBtn',
};
</script>

<style scoped>
.options-icon {
  font-size: 16px;
  color: #bfc1c5;
}

.options-icon:hover {
  color: #525252;
  cursor: pointer;
}

</style>
